import auth from '@/middleware/auth.js'

export default [
	{
		path: ''
		,component: () => import('@/layouts/Constelacion')
		,children: [
			{
				path: '/alianzas/logs'
				,name: 'alianzas_logs'
				,component: () => import('@/apps/alianzas_logs/pages/Dashboard')
				,meta: {
					title: 'Logs'
					,middleware: auth
				}
			}
		]
	}
];